import {ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {PluginControl} from "../plugin-control.abstract";
import {Subject, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {
  SwitchableLightValue
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/switchable-light-value";
import {PolledData} from "../../../../../core/models/tesseract/polledData";

@Component({
  selector: 'gmao-switchable-light-plugin',
  templateUrl: './switchable-light-plugin.component.html',
  styleUrls: ['./switchable-light-plugin.component.scss']
})
export class SwitchableLightPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  @Input('templateMode') templateMode: boolean = false;
  value: number;
  subject: Subject<boolean> = new Subject<boolean>();
  subscription: Subscription = new Subscription();
  mode: number;
  status: boolean = false;
  on: number;
  off: number;
  plotData: PolledData[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.initValue();
  }

  private initValue() {
    this.subscription.add(
      this.subject.pipe(debounceTime(1000)).subscribe((isFirstAction: boolean) => {
        this.setMode(isFirstAction);
      })
    );

    this.on = this.configuration?.additionalInfo?.['on'];
    this.off = this.configuration?.additionalInfo?.['off'];
    if (this.templateMode) {
      this.setValue(this.configuration?.setValue);
    }
  }


  setMode(isFirstAction: boolean) {
    this.mode = this.mode === this.on ? this.off : this.on;
    this.configuration.setValue = this.mode;
    let data: SwitchableLightValue = {
      value: this.mode,
      isFirstAction: isFirstAction
    }
    this.outputValueActions.next(data);
    this.status = this.mode == this.on;
  }

  setValue(value: number) {
    this.value = this.mode = value;
    this.status = this.mode == this.on;
    this.changeDetectorRef.markForCheck();
  }

  switchMode(event: MatSlideToggleChange) {
    this.subject.next(event.checked);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getValue(value: number) {
  }
}
